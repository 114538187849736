
























































































































































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import "vue-swatches/dist/vue-swatches.css";
import { computed } from "@vue/composition-api";
import {
  BListGroup,
  BListGroupItem,
  BDropdown,
  BDropdownDivider,
  BDropdownForm,
  BDropdownGroup,
  BDropdownItem,
  BFormCheckbox,
  BFormGroup,
  BTabs,
  BCardText,
  BTab,
  BButtonGroup,
  BButton,
} from "bootstrap-vue";
import { ETypeLine } from "@/types/api-orisis/enums/enums";
import draggable from "vuedraggable";
import PopupCreatePlanningLine from "@/components/planning/planning-settings/popup-planning-settings/PopupCreatePlanningLine.vue";
import PopupPlanningViewUsersShared from "@/components/planning/planning-settings/popup-planning-settings/PopupPlanningViewUsersShared.vue";
import { LinePositionRequestDto, PlanningViewRequestDto } from "Api";

@Component({
  components: {
    BListGroup,
    BListGroupItem,
    draggable,
    BDropdown,
    BDropdownDivider,
    BDropdownForm,
    BDropdownGroup,
    BDropdownItem,
    BFormCheckbox,
    BFormGroup,
    PopupCreatePlanningLine,
    PopupPlanningViewUsersShared,
    BTabs,
    BCardText,
    BTab,
    BButtonGroup,
    BButton,
  },

  directives: {
    Ripple,
  },
  methods: {
    ...mapActions([
      "storePlanningView",
      "fetchDetailPlanningView",
      "fetchPlanningLinesCollaborator",
      "fetchPlanningLinesFreeLine",
      "fetchPlanningLinesAffair",
      "fetchAllUsersByWorkspaceId"
    ]),
  },
  computed: {
    ...mapGetters([
      "isPlanningViewCreating",
      "createdDataPlanningView",
      "workspaceSelected",
      "isLoadingDetail",
    ]),
  },
})
export default class PopupCreatePlanningView extends Vue {
  //** Datas  */
  planningViewInstance: PlanningViewRequestDto = {
    id:0,
    name: "",
    workspaceId: "",
    linePositions: [],
  };
  search: string = "";
  get planningLinesCollaborator(): IPlanningLine {
    return this.$store.getters.planningLinesCollaborator;
  };
  query = {
    workspaceId: this.$store.getters.workspaceSelected,
  };

  //** Created */
  created() {
    this.getStoreDetailPlanningView(this.$route.params.id);
    this.$store.dispatch("fetchPlanningLinesCollaborator");
    this.$store.dispatch("fetchPlanningLinesFreeLine");
    this.$store.dispatch("fetchPlanningLinesAffair");
    // this.fetchAllUsersByWorkspaceId();
  }

  //** Methods */
  addTitleToLinePosition(typeLine: ETypeLine) {
    let TitleLineTemplate: LinePositionRequestDto = {
      position: 0,
      typeLine: typeLine,
      planningLineId: "",
      isLocked: true,
    };
    if(this.planningViewInstance.linePositions) this.planningViewInstance.linePositions.push(TitleLineTemplate);

  }
  getPlanningViewInstance(): void {
    let planningViewInstance: PlanningViewRequestDto = {
      id:0,
      name: "",
      workspaceId: this.$store.getters.workspaceSelected,
      linePositions: [],
    };
    this.planningViewInstance = planningViewInstance;
  }
  removeItem(index) {
    this.planningViewInstance!.linePositions!.splice(index, 1);
  }
  onChange(evt) {
    if (evt.added && !this.planningViewInstance!.linePositions![evt.added.newIndex].position) {
      let planningLineStock = JSON.parse(JSON.stringify(this.planningViewInstance!.linePositions![evt.added.newIndex]))
      this.planningViewInstance!.linePositions![evt.added.newIndex] = {
        position: this.planningViewInstance.linePositions!.length,
        typeLine: ETypeLine.CollaboratorLine,
        planningLineId: planningLineStock.id as string,
        isLocked: true,
      };
    }
  }
  getStoreDetailPlanningView(id: string): void {
    this.getPlanningViewInstance();
  }
  create(): void {
    this.planningViewInstance.id = 0 ; //this.id ? this.id : 0;
    this.$nextTick(() => {
      console.log("planningViewInstance", this.planningViewInstance, this.planningViewInstance.id )
      this.$store.dispatch("storePlanningView", {
        planningViewPayload: this.planningViewInstance,
        that: this,
      });
    })
  }

  //** Watches */
  @Watch("$route.params.id")
  viewTargetChanged(id) {
    this.getStoreDetailPlanningView(id);
    this.$store.dispatch("fetchPlanningLinesCollaborator");
    this.$store.dispatch("fetchPlanningLinesFreeLine");
    // this.fetchAllUsersByWorkspaceId({query: this.query, that:this});
  }
  @Watch("createdDataPlanningView")
  propertyChanged() {
    if (
      this.$store.getters.planningView !== null &&
      (!this.$store.getters.isPlanningViewCreating as Boolean)
    ) {
      this.$bvToast.toast(
        'La vue de planning "' +
          this.$store.getters.planningView.name +
          '" a bien été créée',
        {
          title: `Création de la vue de planning`,
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        }
      );
      this.$router.push("planningViews")
    }
  }
}
