



















































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

//import { required, url, email } from '@validations'
import { mapGetters, mapActions } from 'vuex'
import Ripple from 'vue-ripple-directive'
import 'vue-swatches/dist/vue-swatches.css'
import { InsertPlanningLine } from '@/types/api-orisis/interfaces/Planning/PlanningLine'

@Component({
  components: {},

  directives: {
    Ripple
  },
  methods: {
    storePlanningLine () {},
  },
  computed: {
    ...mapGetters([
      'isPlanningViewCreating',
      'createdDataPlanningView',
      'workspaceSelected',
      'isLoadingDetail'
    ])
  }
})
export default class PopupCreatePlanningList extends Vue {
  freeLine: InsertPlanningLine = {
    name: "",
    subName: "",
    workspaceId: this.$store.getters.workspaceSelected
  }
  $refs!: {
    formCreatePlanningView: HTMLFormElement
  }
  create (bvModalEvt): void {
    bvModalEvt.preventDefault()
     this.$store.dispatch('storePlanningLine', {
      planningLinePayload: this.freeLine,
      that: this
    })
    this.$nextTick(() => {
      this.$refs['modal-create-planning-line'].toggle('#toggle-btn')
    })
  }
}
