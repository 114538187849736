




























































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import "vue-swatches/dist/vue-swatches.css";
import { InsertPlanningLine } from "@/types/api-orisis/interfaces/Planning/PlanningLine";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

@Component({
  components: {
    vSelect,
  },
  directives: {
    Ripple,
  },
  methods: {
    storePlanningLine() {},
  },
  computed: {
    ...mapGetters(["userList", "isLoadingUser"]),
  },
})
export default class PopupPlanningViewUsersShared extends Vue {
  //** Props  */
  @Prop({ default: {} })
  planningViewInstance!: object;

  freeLine: InsertPlanningLine = {
    name: "",
    subName: "",
    workspaceId: this.$store.getters.workspaceSelected,
  };
  $refs!: {
    formCreatePlanningView: HTMLFormElement;
  };
  share(bvModalEvt): void {
    bvModalEvt.preventDefault();
    this.$store.dispatch("storePlanningLine", {
      planningLinePayload: this.freeLine,
      that: this,
    });
    this.$nextTick(() => {
      this.$refs["modal-planning-view-users-shared"].toggle("#toggle-btn");
    });
  };
  removeShared(index) {

  }
}
